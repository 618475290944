import React from 'react'
import CampaignsNavbar from './CampaignsNavbar/CampaignsNavbar'

function Campaigns() {
  return (
    <div>
        <CampaignsNavbar/>
    </div>
  )
}

export default Campaigns