import React from 'react'
import { Outlet,Link } from 'react-router-dom'

function HourComp() {
  return (
    <div>

<div className="navbar-comp">
      <div className="main-navbar">
        <li>
          <Link to='day_of_week'>Day of Week</Link>
        </li>

        <li>
          <Link to='hour_of_day'>Hour of Day</Link>
        </li>
      </div>
    </div>
    <Outlet/>
    </div>
  )
}

export default HourComp