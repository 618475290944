import React from 'react'
import { Outlet,Link } from 'react-router-dom'

function AdFormat() {
  return (
    <div>

<div className="navbar-comp">
        <div className="main-navbar">
          <li>
            <Link to='adformatlast'>Adformat lsat</Link>
          </li>

          <li>
            <Link to='zonetype'>ZoneTypes</Link>
          </li>
        </div>
      </div>
      <Outlet/>
      


    </div>
  )
}

export default AdFormat