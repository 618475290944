import React from 'react'
import { Link,Outlet } from 'react-router-dom'
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function StatisticsNavbar() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <div>

<Box
      sx={{
        flexGrow: 1,
        // maxWidth: { xs: 320, sm: 480 },
        bgcolor: 'background.paper',
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >

                <div className="navbar-comp">


      <div className="main-navbar">
    

      
          <li>
           <Link to='dates'>Dates</Link>
          </li>

          <li>
     <Link to='variations'>Champaints & Variations</Link>
          </li>

          <li>
          <Link to='loadingpages'>LoadingPages</Link>
          </li>

          <li>
       <Link to='sites'>Sites</Link>
          </li>

          <li>
       <Link to='categories'>Categories</Link>
          </li>

          <li>
       <Link to='locations'>locations</Link>
          </li>

          <li>
       <Link to='devices'>Devices</Link>
          </li>

          <li>
       <Link to='operatingsystem'>OperatingSystems</Link>
          </li>

          <li>
       <Link to='language'>Language</Link>
          </li>

          <li>
       <Link to='browser'>Browser</Link>
          </li>

          <li>
       <Link to='carriers'>Carriers</Link>
          </li>

          <li>
       <Link to='adblock'>Ad Block</Link>
          </li>

          <li>
       <Link to='vrcomp'>VR</Link>
          </li>

          <li>
       <Link to='adformat'>AD Format</Link>
          </li>

          <li>
       <Link to='hour'>Hour/Day</Link>
          </li>
      
      
      
      

     
      </div>
      
    </div>

   

      </Tabs>
    </Box>
           {/* <div className="navbar-comp">


      <div className="main-navbar">
    

      
          <li>
           <Link to='dates'>Dates</Link>
          </li>

          <li>
     <Link to='variations'>Variations</Link>
          </li>

          <li>
          <Link to='loadingpages'>LoadingPages</Link>
          </li>

          <li>
       <Link to='sites'>Sites</Link>
          </li>

          <li>
       <Link to='categories'>Categories</Link>
          </li>

          <li>
       <Link to='locations'>locations</Link>
          </li>

          <li>
       <Link to='devices'>Devices</Link>
          </li>

          <li>
       <Link to='operatingsystem'>OperatingSystems</Link>
          </li>

          <li>
       <Link to='language'>Language</Link>
          </li>

          <li>
       <Link to='browser'>Browser</Link>
          </li>

          <li>
       <Link to='carriers'>Carriers</Link>
          </li>

          <li>
       <Link>Ad Block</Link>
          </li>

          <li>
       <Link>VR</Link>
          </li>

          <li>
       <Link>AD Format</Link>
          </li>

          <li>
       <Link>Hour/Day</Link>
          </li>
      
      
      
      

     
      </div>
      
    </div> */}
    <Outlet/>
    </div>
  )
}

export default StatisticsNavbar