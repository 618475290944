import React from 'react'
import { Link,Outlet } from 'react-router-dom'

function Locations() {
  return (
    <div>
    <div className="navbar-comp">
      <div className="main-navbar">
        <li>
          <Link to='countries'>Countries</Link>
        </li>

        <li>
          <Link to='regions'>Regions</Link>
        </li>
      </div>
    </div>
    <Outlet/>
  </div>
  )
}

export default Locations