import React, { useState } from "react";
import "./DashBoard.css";
import list1 from "../Images/list1.png";
import list2 from "../Images/list2.png";
import list3 from "../Images/list3.png";
import image5 from "../Images/image 5.png";
import refresh from "../Images/refresh.png";
import delet from "../Images/delete.png";
import collect from "../Images/collect.png";
import { Grid } from "@mui/material";
// import DonutChart from 'react-donut-chart';
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

function DashBoard() {
  
  const data = {
    labels: ["Mobile", "Desktop", "Tablet"],
    datasets: [
      {
        data: [50, 25, 25],
        backgroundColor: ["#E85D42", "#ED6E6E", "#E5C7C5"],
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  return (
    <div className="dashboard-comp">
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <div className="dashboard-box1">
            <h1>Total Revenue</h1>
            <div className="inside-box1-flex">
              <h2>$203.378</h2>
              <p>+6.32%</p>
            </div>
          </div>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <div className="dashboard-box1">
            <h1>Total Costumer</h1>
            <div className="inside-box1-flex">
              <h2>54,544</h2>
              <p>+3.54%</p>
            </div>
          </div>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <div className="dashboard-box1">
            <h1>Total profit</h1>
            <div className="inside-box1-flex">
              <h2>$333.653</h2>
              <p>+8.12%</p>
            </div>
          </div>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
          <div className="dashboard-box1">
            <h1>Spending Statistic</h1>
            <div className="inside-box1-flex">
              <h2>$10.823,43</h2>
              <p>+6.22%</p>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="image-box-flex">
        <div className="content-box">
          <div className="language-dailybudget">
            <div className="language-flex">
              <h1>Language</h1>
              <input></input>
            </div>

            <div className="budget-flex">
              <h1>Daily budget</h1>
              <input></input>
            </div>

            <div className="button-flex">
              <a href="#">Click Here</a>
              <p>if your ad is Gamblig, NSFW, Investments or Risky</p>
            </div>
          </div>

          <div>
            <div className="second-box1-parent-flex">
              <div className="second-inside-box1">
                <h1>1545</h1>
                <p>Impressions per day</p>
              </div>

              <div className="second-inside-box2">
                <h1>31</h1>
                <p>Clicks per day</p>
              </div>
            </div>

            <div className="second-box2-parent-flex">
              <div className="second-inside-box3">
                <h1>$13.66</h1>
                <p>cpm</p>
              </div>

              <div className="second-inside-box4">
                <h1>81</h1>
                <p>Impressions per day</p>
              </div>
            </div>
          </div>
        </div>

        <div className="second-image-list-flex">
          {/* <div className="image-heading-list">
            <h1>Order By Device</h1>
            <p>image</p>

            <li>
              <img src={list1}></img>
              <span>Mobile</span>
            </li>
            <li>
              <img src={list2}></img>
              <span>Desktop</span>
            </li>
            <li>
              <img src={list3}></img>
              <span>Tablet</span>
            </li>


          </div> */}

          <Doughnut data={data} options={options} />
        </div>
      </div>

      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="grid-box1">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              lg={6}
              xs={6}
              sm={6}
              style={{
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "10px",
                fontStyle: "normal",
                color: "#000000",
              }}
            >
              <h1>Create advertiement</h1>
            </Grid>

            <Grid
              item
              md={6}
              lg={6}
              style={{ textAlign: "end" }}
              className="date-inputbox"
            >
              <input type="date"/>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#000000",
              }}
            >
              <p>Title</p>
            </Grid>

            <Grid item md={4} lg={4} xs={4} sm={4} className="date-inputbox1">
              <input placeholder="your Ad Title"></input>
            </Grid>

            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{ paddingLeft: "60px" }}
            >
              0/50
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#000000",
              }}
            >
              <p>Text</p>
            </Grid>

            <Grid item md={4} lg={4} xs={4} sm={4} className="date-inputbox1">
              <input placeholder="your Ad Title"></input>
            </Grid>

            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{ paddingLeft: "60px" }}
            >
              0/50
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#000000",
              }}
            >
              Link
            </Grid>

            <Grid item md={4} lg={4} xs={4} sm={4} className="date-inputbox1">
              <input placeholder="your Ad Title"></input>
            </Grid>

            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{ paddingLeft: "60px" }}
            >
              0/200
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "5px" }}>
            <Grid
              item
              md={4}
              lg={4}
              xs={4}
              sm={4}
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#000000",
              }}
            >
              Banners
            </Grid>

            <Grid item md={4} lg={4} xs={4} sm={4} className="date-inputbox1">
              <input placeholder="your Ad Title" />
            </Grid>
          </Grid>

          <div className="last-heading-para">
            <p>
              Sizes of the targeted ad units: 468x60, 728x90, 970x90, 300x250,
              320x100, 300x600, 250x250, 320x50, 336x280, 160x600, 240x400,
              120x600, 200x200, 120x60, 970x250, 125x125
            </p>
            <h5>Each banner must be a JPG, PNG or GIF file, up to 700 Kb</h5>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="grid-box2">
          <div className="last-grp-img">
            <img src={refresh}></img>
            <img src={delet}></img>
            <img src={collect}></img>
          </div>
          <div className="last-img">
            <img src={image5}></img>
            <p>Preview in all sizes</p>
          </div>

          <div className="last-btn">
            <button>Chart</button>
            <button>Table</button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
export default DashBoard;
