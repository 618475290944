
import { useRoutes } from "react-router-dom";
import "./App.css";
import DashBoard from "./Components/DashBoard";
import DataBinding from "./Components/DataBinding/DataBinding";
import NavBar from "./NavBar/NavBar";
import Statistic from "./Components/Statistic/Statistic";
import Campaigns from "./Components/Campaigns/Campaigns";
import Payments from "./Components/Payments/Payments";
import MarketPlace from "./Components/MarketPlace/MarketPlace";
import CampaignsList from "./Components/Campaigns/CampaignsNavbar/CampaignsComponents/CampaignsList";
import LandingPlace from "./Components/Campaigns/CampaignsNavbar/CampaignsComponents/LandingPlace";
import Conversion from "./Components/Campaigns/CampaignsNavbar/CampaignsComponents/Conversion";
import Bidder from "./Components/Campaigns/CampaignsNavbar/CampaignsComponents/Bidder";
import Dates from "./Components/Statistic/Statisticcomponents/Dates";
import Variations from "./Components/Statistic/Statisticcomponents/Variations";
import LoadingPages from "./Components/Statistic/Statisticcomponents/LoadingPages";
import Sites from "./Components/Statistic/Statisticcomponents/Sites";
import Categories from "./Components/Statistic/Statisticcomponents/Categories";
import Locations from "./Components/Statistic/Statisticcomponents/Locations";
import Devices from "./Components/Statistic/Statisticcomponents/Devices";
import OpertingSystem from "./Components/Statistic/Statisticcomponents/OpertingSystem";
import Language from "./Components/Statistic/Statisticcomponents/Language";
import Browser from "./Components/Statistic/Statisticcomponents/Browser";
import Carriers from "./Components/Statistic/Statisticcomponents/Carriers";
import AdBlock from "./Components/Statistic/Statisticcomponents/AdBlock";
import Vrcomp from "./Components/Statistic/Statisticcomponents/Vrcomp";
import AdFormat from "./Components/Statistic/Statisticcomponents/AdFormat";
import HourComp from "./Components/Statistic/Statisticcomponents/HourComp";
import Years from "./Components/Statistic/Statisticcomponents/DatesComp/Years";
import Month from "./Components/Statistic/Statisticcomponents/DatesComp/Month";
import Week from "./Components/Statistic/Statisticcomponents/DatesComp/Week";
import Day from "./Components/Statistic/Statisticcomponents/DatesComp/Day";
import Hour from "./Components/Statistic/Statisticcomponents/DatesComp/Hour";
import DoMain from "./Components/Statistic/Statisticcomponents/SitesComponents/DoMain";
import SitesZones from "./Components/Statistic/Statisticcomponents/SitesComponents/SitesZones";
import AdZone from "./Components/Statistic/Statisticcomponents/SitesComponents/AdZone";
import SubId from "./Components/Statistic/Statisticcomponents/SitesComponents/SubId";
import ChampaintsVariants from "./Components/Statistic/Statisticcomponents/VariationsComponent/ChampaintsVariants";
import VariationLast from "./Components/Statistic/Statisticcomponents/VariationsComponent/VariationLast";
import Group from "./Components/Statistic/Statisticcomponents/GroupComponents/Group";
import Landing from "./Components/Statistic/Statisticcomponents/GroupComponents/Landing";
import Countries from "./Components/Statistic/Statisticcomponents/LocationComponent/Countries";
import Regions from "./Components/Statistic/Statisticcomponents/LocationComponent/Regions";
import DeviceType from "./Components/Statistic/Statisticcomponents/DeviceComponent/DeviceType";
import DeviceLast from "./Components/Statistic/Statisticcomponents/DeviceComponent/DeviceLast";
import AdformatLast from "./Components/Statistic/Statisticcomponents/AdformatComponent/AdformatLast";
import ZoneType from "./Components/Statistic/Statisticcomponents/AdformatComponent/ZoneType";
import DayWeek from "./Components/Statistic/Statisticcomponents/HourComponent/DayWeek";
import HourDay from "./Components/Statistic/Statisticcomponents/HourComponent/HourDay";

function App() {
  const router = useRoutes([
    {
      path: "/",
      element: <NavBar />,

      children: [
        {
          path: "dashboard",
          element: <DashBoard />,
        },

        {
          path: "statistic",
          element: <Statistic />,
          children: [
            {
              path: "dates",
              element: <Dates />,
              children: [
                {
                  path: "years",
                  element: <Years />,
                },

                {
                  path: "month",
                  element: <Month />,
                },

                {
                  path: "week",
                  element: <Week />,
                },

                {
                  path: "day",
                  element: <Day />,
                },

                {
                  path: "hour",
                  element: <Hour />,
                },
              ],
            },

            {
              path: "variations",
              element: <Variations />,
              children: [
                {
                  path: "champaintsvariants",
                  element: <ChampaintsVariants />,
                },

                {
                  path: "variantslast",
                  element: <VariationLast />,
                },
              ],
            },

            {
              path: "loadingpages",
              element: <LoadingPages />,
              children: [
                {
                  path: "group",
                  element: <Group />,
                },

                {
                  path: "landing",
                  element: <Landing />,
                },
              ],
            },

            {
              path: "sites",
              element: <Sites />,
              children: [
                {
                  path: "domain",
                  element: <DoMain />,
                },

                {
                  path: "sitezone",
                  element: <SitesZones />,
                },

                {
                  path: "adzone",
                  element: <AdZone />,
                },

                {
                  path: "subid",
                  element: <SubId />,
                },
              ],
            },

            {
              path: "categories",
              element: <Categories />,
            },

            {
              path: "locations",
              element: <Locations />,
              children: [
                {
                  path: "countries",
                  element: <Countries />,
                },

                {
                  path: "regions",
                  element: <Regions />,
                },
              ],
            },

            {
              path: "devices",
              element: <Devices />,
              children: [
                {
                  path: "devicetype",
                  element: <DeviceType />,
                },
                {
                  path: "devicelast",
                  element: <DeviceLast />,
                },
              ],
            },

            {
              path: "operatingsystem",
              element: <OpertingSystem />,
            },

            {
              path: "language",
              element: <Language />,
            },

            {
              path: "browser",
              element: <Browser />,
            },

            {
              path: "carriers",
              element: <Carriers />,
            },

            {
              path: "adblock",
              element: <AdBlock />,
            },

            {
              path: "vrcomp",
              element: <Vrcomp />,
            },

            {
              path: "adformat",
              element: <AdFormat />,
              children:[
                {
                  path: "adformatlast",
                  element: <AdformatLast />,
                },
                {
                  path: "zonetype",
                  element: <ZoneType/>,
                },
              ]
            },

            {
              path: "hour",
              element: <HourComp />,
              children:[
                {
                  path: "day_of_week",
                  element: <DayWeek />,
                },
                {
                  path: "hour_of_day",
                  element: <HourDay/>,
                },
              ]

            },
          ],
        },

        {
          path: "campaigns",
          element: <Campaigns />,
          children: [
            {
              path: "campaignslist",
              element: <CampaignsList />,
            },

            {
              path: "landingplace",
              element: <LandingPlace />,
            },

            {
              path: "conversiontracking",
              element: <Conversion />,
            },

            {
              path: "bidder",
              element: <Bidder />,
            },

            {
              path: "marketplace",
              element: <MarketPlace />,
            },
          ],
        },

        {
          path: "payments",
          element: <Payments />,
        },

        {
          path: "marketplace",
          element: <MarketPlace />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      {router}
      {/* <NavBar/> */}
      {/* <DashBoard/> */}
      {/* <DataBinding/> */}
    </div>
  );
}

export default App;
