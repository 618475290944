import React from 'react'
import StatisticsNavbar from './Statisticcomponents/StatisticsNavbar'

function Statistic() {
  return (
    <div>
        <StatisticsNavbar/>
    </div>
  )
}

export default Statistic