import React from 'react'
import { Link, Outlet } from 'react-router-dom'


function CampaignsNavbar() {
  return (
    <div>
        <div className="navbar-comp">
      <div className="main-navbar">
    

      
          <li>
           <Link to='campaignslist'>campaignslist</Link>
          </li>

          <li>
     <Link to='landingplace'>LandingPages</Link>
          </li>

          <li>
          <Link to='conversiontracking'>conversiontracking</Link>
          </li>

          <li>
       <Link to='bidder'>Bidder</Link>
          </li>

          <li>
       <Link to='marketplace'>MarketPlace</Link>
          </li>
      

     
      </div>
      
    </div>

    <Outlet/>


    </div>
  )
}

export default CampaignsNavbar