import React from 'react'
import { Link,Outlet } from 'react-router-dom'

function Sites() {
  return (
    <div>

<div className="navbar-comp">
      <div className="main-navbar">


        {/* <div> */}
          <li>
            {/* <a href="#">Dashboard</a> */}
            <Link to='domain'>Domains</Link>
          </li>
          <li>
          <Link to='sitezone'>Sites </Link>

          </li>
          <li>
          <Link to='adzone'>AdZones</Link>

          </li>
          <li>
          <Link to='subid'>Sub IDs</Link>
          </li>
        
        {/* </div> */}

     
      </div>
      
    
      
    </div>

<Outlet/>


    </div>
  )
}

export default Sites