import React from 'react'
import { Link,Outlet } from 'react-router-dom'

function Devices() {
  return (
    <div>
    <div className="navbar-comp">
      <div className="main-navbar">
        <li>
          <Link to='devicetype'>Device Type</Link>
        </li>

        <li>
          <Link to='devicelast'>Device</Link>
        </li>
      </div>
    </div>
    <Outlet/>
  </div>
  )
}

export default Devices