import React from "react";
import { Link,Outlet } from "react-router-dom";

function LoadingPages() {
  return (
    <div>
      <div className="navbar-comp">
        <div className="main-navbar">
          <li>
            <Link to='group'>Groups</Link>
          </li>

          <li>
            <Link to='landing'>LandingPages</Link>
          </li>
        </div>
      </div>
      <Outlet/>
    </div>
  );
}

export default LoadingPages;
