import React from 'react'
import { Link,Outlet } from 'react-router-dom'

function Variations() {
  return (
    <div>
      <div className="navbar-comp">


<div className="main-navbar">



    <li>
     <Link to='champaintsvariants'>Champaints</Link>
    </li>

    <li>
<Link to='variantslast'>Variations</Link>
    </li>

</div>
</div>
<Outlet/>
    </div>
  )
}

export default Variations