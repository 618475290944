import React from 'react'
import { Link,Outlet } from 'react-router-dom'

function Dates() {
  return (
    <div>

<div className="navbar-comp">
      <div className="main-navbar">


        {/* <div> */}
          <li>
            {/* <a href="#">Dashboard</a> */}
            <Link to='years'>Year</Link>
          </li>
          <li>
          <Link to='month'>Month </Link>

          </li>
          <li>
          <Link to='week'>Week</Link>

          </li>
          <li>
          <Link to='day'>Day</Link>
          </li>
          <li>
       <Link to='hour'>Hour</Link>
          </li>
        {/* </div> */}

     
      </div>
      
    
      
    </div>

<Outlet/>

    </div>
  )
}

export default Dates