import React from "react";
import "./NavBar.css";
import logo from '../Images/logo.png'
import vector from '../Images/Vector.png'
import Account from '../Images/Account.png'
import { Link, Outlet } from "react-router-dom";
import DashBoard from "../Components/DashBoard";



function NavBar() {
  return (
    <div className="background-class">
    <div className="navbar-comp">
      <div className="main-navbar">
     <img src={logo}/><span>Ads</span>

        {/* <div> */}
          <li>
           
            <Link to='/dashboard'>Dashboard</Link>
          </li>
          <li>
          <Link to='/statistic'>Statistic </Link>

          </li>
          <li>
          <Link to='/campaigns'>Campaigns</Link>

          </li>
          <li>
          <Link to="/payments">Payments</Link>
          </li>
          <li>
       <Link to='marketplace'>MarketPlace</Link>
          </li>
        {/* </div> */}

     
      </div>
      
      <div className="parent-flex">
       <input placeholder="Search"></input>
       <img src={vector}/>
        <p>Anand</p>
        <img src={Account} className="acount-img"/>
      </div>
      
    </div>
    <Outlet/>

   
  
 
  
    </div>

  );
}

export default NavBar;
